import { sync as syncLog } from '@/utils/logging';

const MINUTE = 1000 * 60;

const DEV = false;

export const useQueue = defineStore('queue', () => {
  /**
   * State
   */
  const pending: Ref<number> = ref(0);

  /**
   * Getters
   */
  const byType = (type: QueueItem['type']) =>
    database.queue.where('type').equals(type).toArray();

  /**
   * Actions
   */
  const add = (item: QueueItem) => database.queue.put(item);
  const remove = (item: QueueItem) =>
    database.queue.where({ id: item.id, type: item.type }).delete();
  const sync = async (force = false) => {
    DEV && console.log('Sync() ...');

    // Sync with the data from the server.
    // log('sync.queue', 'Syncing data from server.');
    await syncLog();
    // await useQuizSubmission().sync();

    // Sync the queue.
    // log('sync.queue', 'Syncing queue.');

    const itemsInQueue = await database.queue.toArray();

    pending.value = itemsInQueue.length;

    DEV && console.log('Sync() ... num items in queue:', itemsInQueue.length);

    // Only try to sync each item max. once per so many minutes, to prevent server overload in the case that it's not responding
    // (With a bit of back-off logic: if the sync has been attempted 4 times, then only try again after 4 minutes.)
    const itemsToSyncNow = force
      ? [...itemsInQueue]
      : itemsInQueue.filter((item) => {
          return (
            !item.lastAttemptedAt ||
            Date.now() - item.lastAttemptedAt > MINUTE // * ((item.numTimesAttempted ?? 0) + 1)
          );
        });

    DEV &&
      console.log(
        'Sync() ... num items in attempt now:',
        itemsToSyncNow.length,
      );

    await database.queue.bulkUpdate(
      itemsToSyncNow.map((item) => {
        return {
          key: item.id,
          changes: {
            lastAttemptedAt: Date.now(),
            numTimesAttempted: 1 + (item.numTimesAttempted ?? 0),
          },
        };
      }),
    );

    DEV && console.log('NOW', await database.queue.toArray());

    itemsToSyncNow.forEach(async (item, i) => {
      // Stagger the API calls by 200ms.
      // (If there's 40 students, then that means they'll be staggered over a time span of 8 seconds.)
      await new Promise((r) => setTimeout(r, i * 200));

      DEV && console.log('Sync() ... attempt', item);

      switch (item.type) {
        case 'scan_test':
          await useScanTest()
            .sync(item.id)
            .then(async (testFound) => {
              if (!testFound) {
                // This can never happen, but hey, who knows
                log(
                  'sync.queue',
                  'Locally queued test not found for sync',
                  {
                    item,
                  },
                  'error',
                );
              }

              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing test.',
                { item, error },
                'error',
              );
            });
          break;
        case 'scan_swap':
          await useScanTest()
            .syncSwap(item.payload)
            .then(async () => {
              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing test swap.',
                { item, error },
                'error',
              );
            });
          break;
        case 'quiz_submission':
          await useQuizSubmission()
            .push(item.id)
            .then(async () => {
              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing quiz submission.',
                {
                  item,
                  error,
                },
                'error',
              );
            });
          break;
      }
    });

    // log('sync.queue', 'Finished', null, 'success');
  };

  return {
    // State
    pending,

    // Getters
    byType,

    // Actions
    add,
    sync,
    remove,
  };
});
