<!-- eslint-disable no-prototype-builtins -->
<script setup lang="ts">
import pkg from './package.json';

window.version = pkg.version;
console.log('👍 HDI', pkg.version);

/**
 * State
 */
const degrees = ref(0);

/**
 * Methods
 */
const populateOrientation = (): void => {
  degrees.value =
    screen.hasOwnProperty('orientation') &&
    screen.orientation.hasOwnProperty('angle')
      ? screen.orientation.angle
      : window.orientation;
};

/**
 * Event listeners.
 */
populateOrientation();
window.addEventListener('orientationchange', populateOrientation);
</script>

<template>
  <div class="app select-none" :class="`degrees-${degrees}`">
    <div
      class="bg-red-500 py-1 text-center text-xs text-white"
      v-if="useRuntimeConfig().public.mode === 'test'"
    >
      Testversie
    </div>
    <Alerts />

    <NuxtLayout />
  </div>
  <UpdateAvailable />
</template>

<style lang="scss" scoped>
.app {
  min-height: 100vh;
  color: #300e65;
  @apply flex flex-col;

  @media screen and (orientation: portrait) {
    min-height: 100vh;
  }
  @media screen and (orientation: landscape) {
    min-height: 100vw;
  }
}

.degrees-90,
.degrees--90,
.degrees-270 {
  transform-origin: left top;
  width: 100vh;
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
}

.degrees-90 {
  transform: rotate(-90deg);
  top: 100%;
  left: 0;
}
.degrees--90,
.degrees-270 {
  transform: rotate(90deg);
  bottom: 0;
  right: 100%;
  transform-origin: right bottom;
}
</style>
