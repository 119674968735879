import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _1_46startXBhqBZj7o3Meta } from "/app/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionO71UugwvjMMeta } from "/app/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishp6RWAvd3GlMeta } from "/app/pages/quiz/form/3.finish.vue?macro=true";
import { default as formlApVK5ifbeMeta } from "/app/pages/quiz/form.vue?macro=true";
import { default as homeAhKf5nAmQDMeta } from "/app/pages/quiz/home.vue?macro=true";
import { default as onboarding7oE2yOJJACMeta } from "/app/pages/quiz/onboarding.vue?macro=true";
import { default as preparebuZBFieF0BMeta } from "/app/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsR1duXzPcEgMeta } from "/app/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsvUC6OrkuZYMeta } from "/app/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionLvYgQyRI2CMeta } from "/app/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46results2FBlqJ08uQMeta } from "/app/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectXL70RXULWBMeta } from "/app/pages/quiz/select.vue?macro=true";
import { default as finish2hQQCCXtG7Meta } from "/app/pages/scan/finish.vue?macro=true";
import { default as homesFqkLv9Xk7Meta } from "/app/pages/scan/home.vue?macro=true";
import { default as onboardingx2Ca2ybWDCMeta } from "/app/pages/scan/onboarding.vue?macro=true";
import { default as preparewYAX5IgHNRMeta } from "/app/pages/scan/prepare.vue?macro=true";
import { default as resumeFF0aKqfFxcMeta } from "/app/pages/scan/resume.vue?macro=true";
import { default as scanbUOcZLWpvbMeta } from "/app/pages/scan/scan.vue?macro=true";
import { default as selectXB2ZV6OmiMMeta } from "/app/pages/scan/select.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as systemcqsl280M4dMeta } from "/app/pages/system.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form",
    path: "/quiz/form",
    component: () => import("/app/pages/quiz/form.vue").then(m => m.default || m),
    children: [
  {
    name: "quiz-form-1.start",
    path: "1.start",
    component: () => import("/app/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-2.session",
    path: "2.session",
    component: () => import("/app/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-3.finish",
    path: "3.finish",
    component: () => import("/app/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz-home",
    path: "/quiz/home",
    component: () => import("/app/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: "quiz-onboarding",
    path: "/quiz/onboarding",
    component: () => import("/app/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "quiz-prepare",
    path: "/quiz/prepare",
    component: () => import("/app/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-1.create-teams",
    path: "/quiz/quiz/1.create-teams",
    component: () => import("/app/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-2.teams",
    path: "/quiz/quiz/2.teams",
    component: () => import("/app/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-3.session",
    path: "/quiz/quiz/3.session",
    component: () => import("/app/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-4.results",
    path: "/quiz/quiz/4.results",
    component: () => import("/app/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: "quiz-select",
    path: "/quiz/select",
    component: () => import("/app/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: "scan-finish",
    path: "/scan/finish",
    component: () => import("/app/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: "scan.home",
    path: "/scan/home",
    meta: homesFqkLv9Xk7Meta || {},
    component: () => import("/app/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: "scan-onboarding",
    path: "/scan/onboarding",
    component: () => import("/app/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "scan.prepare",
    path: "/scan/prepare",
    meta: preparewYAX5IgHNRMeta || {},
    component: () => import("/app/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: "scan.resume",
    path: "/scan/resume",
    meta: resumeFF0aKqfFxcMeta || {},
    component: () => import("/app/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: "scan-scan",
    path: "/scan/scan",
    component: () => import("/app/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: "scan-select",
    path: "/scan/select",
    component: () => import("/app/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: systemcqsl280M4dMeta || {},
    component: () => import("/app/pages/system.vue").then(m => m.default || m)
  }
]