import { v4 } from 'uuid';
import { consola } from 'consola';
import { database } from './database';
import logMutation from '@/graphql/mutations/log.gql';

export async function log(
  action: string,
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any = null,
  level: 'info' | 'warning' | 'error' | 'success' = 'info',
): Promise<void> {
  // Log the action to the database.
  await database.logs.add({
    id: v4(),
    action,
    message,
    context: JSON.stringify(context),
    level,
    created_at: new Date(),
  });

  // Log the action to the console.
  switch (level) {
    default:
    case 'info':
      consola.info(`[${action}]`, message, context);
      break;
    case 'success':
      consola.success(`[${action}]`, message, context);
      break;
    case 'warning':
      consola.warn(`[${action}]`, message, context);
      break;
    case 'error':
      consola.error(`[${action}]`, message, context);
      break;
  }
}

export async function sync(): Promise<void> {
  // Retrieve all logs from the database.
  const logs = await database.logs.toArray();

  // Do not send logs if there are none.
  if (!logs.length) return;

  // Send the logs to the server.
  // TODO [kelley]: this is a "fire and forget" call, but .. is that really what we want?
  try {
    useMutation(logMutation, {
      variables: {
        input: {
          device_id: deviceId(),
          entries: logs,
        },
      },
    }).mutate();

    // Remove the logs from the database.
    await database.logs.bulkDelete(logs.map((log: LogModel) => log.id));
  } catch (error) {
    //
  }
}
