import Dexie from 'dexie';

class MQScanDatabase extends Dexie {
  alerts!: Dexie.Table<AlertModel, string>;
  tests!: Dexie.Table<TestModel, string>;
  groups!: Dexie.Table<GroupModel, string>;
  schools!: Dexie.Table<SchoolModel, string>;
  children!: Dexie.Table<ChildModel, string>;
  queue!: Dexie.Table<QueueItem, string>;

  // Quiz
  quiz_teams!: Dexie.Table<Team, string>;
  quiz_quizzes!: Dexie.Table<QuizModel, string>;
  quiz_answers!: Dexie.Table<AnswerModel, string>;
  quiz_absences!: Dexie.Table<AbsenceModel, string>;
  quiz_submissions!: Dexie.Table<Submission, string>;

  logs!: Dexie.Table<LogModel, string>;

  constructor() {
    super('mqscan');

    this.version(21).stores({
      alerts: '&id',
      queue: '&id,type,[id+type]',
      schools: '&id,name',
      groups: '&id,school_id,name',
      children: '&id,group_id',
      tests:
        '&id,group_id,child_id,session,[group_id+session],[child_id+track],[child_id+session]',

      // Quiz
      quiz_teams: '&id,submission_id',
      quiz_quizzes: '&id,school',
      quiz_absences: '&id,submission_id,child_id',
      quiz_submissions: '&id,quiz_id,group_id,completed_at',
      quiz_answers:
        '&id,submission_id,child_id,question_id,option_id,[submission_id+question_id+child_id]',

      // Logging
      logs: '&id,created_at',
    });
  }
}

export const database = new MQScanDatabase();

if (window) {
  (window as any).db = database;
}
